import { template as template_08ccb8469b754174907dea921027e216 } from "@ember/template-compiler";
const FKText = template_08ccb8469b754174907dea921027e216(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
