import { template as template_272a000cb7bf429c978c887bd3ad322f } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import { i18n } from "discourse-i18n";
const BackToForum = template_272a000cb7bf429c978c887bd3ad322f(`
  <a href={{getURL "/"}} class="sidebar-sections__back-to-forum">
    {{icon "arrow-left"}}

    <span>{{i18n "sidebar.back_to_forum"}}</span>
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackToForum;
